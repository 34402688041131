import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { SyncMapsService } from '../../../services/sync-maps/sync-maps.service';
import { SyncMap, emptySyncMap } from '../../../services/sync-maps/sync-maps.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { emptySyncReader, SyncReader } from '../../../services/sync-readers/sync-readers.model';
import { emptySyncWriter, SyncWriter } from '../../../services/sync-writers/sync-writers.model';
import { SyncReadersService } from '../../../services/sync-readers/sync-readers.service';
import { SyncWritersService } from '../../../services/sync-writers/sync-writers.service';
import { SyncReaderLookupComponent } from '../../sync-readers/sync-reader-lookup/sync-reader-lookup.component';
import { SyncWriterLookupComponent } from '../../sync-writers/sync-writer-lookup/sync-writer-lookup.component';

enum Filters {
  SyncReader = 1,
  SyncWriter = 2
}

@Component({
  selector: 'app-sync-map-single',
  templateUrl: './sync-map-single.component.html',
  styleUrls: ['./sync-map-single.component.scss'],
})
export class SyncMapSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncMap: number | null = null;
  @Input() syncMap: SyncMap = emptySyncMap;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  filters = Filters;
  myResponse: string;
  syncMapForm: FormGroup;
  triggeringEntityTypes: Lookup[] = [];
  queueTypes: Lookup[] = [];
  syncReaders: SyncReader[] = [];
  syncWriters: SyncWriter[] = [];
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  selectedSyncReader: SyncReader = emptySyncReader;
  selectedSyncWriter: SyncWriter = emptySyncWriter;

  constructor(
    private syncMapsService: SyncMapsService,
    private lookupService: LookupsService,
    private syncReaderService: SyncReadersService,
    private syncWriterService: SyncWritersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfSyncMap) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncMapForm = new FormGroup({      
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      triggeringEntityID: new FormControl(null, Validators.required),
      queueTypeID: new FormControl(null, Validators.required),
      rank: new FormControl(''),
      syncReaderID: new FormControl(null, Validators.required),
      syncWriterID: new FormControl(null, Validators.required),
      isActive: new FormControl(true),
      filter: new FormControl(''),
    });
    this.syncMapForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getSyncEntityTypes();
    this.getQueueTypes();
  }
  
  get f() {
    return this.syncMapForm.controls;
  }
  updateForm() {
    this.syncMapForm.patchValue({ ...this.syncMap });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }
  
  async saveRecord() {
    this.syncMapForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncMapForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncMap,
      ...this.syncMapForm.value,
    };
    if (this.syncMap.id) {
      try {
        const response: any = await this.syncMapsService.update(
          this.syncMap.id,
          data,
        );        
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {                
         const response: any = await this.syncMapsService.create(
           this.syncMapForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);

  }

  async getSyncEntityTypes() {
    const Filter_OptionSet = 'SyncEntityType';
    const params = { Filter_OptionSet };
    this.triggeringEntityTypes = await this.lookupService.getList(params);
    return this.triggeringEntityTypes;
  }
  
  async getQueueTypes() {
    const Filter_OptionSet = 'QueueType';
    const params = { Filter_OptionSet };
    this.queueTypes = await this.lookupService.getList(params); 
    return this.queueTypes;
  }
     
  async getSyncMapById() {
    if (this.idOfSyncMap) {
      return await this.syncMapsService.getById(
        this.idOfSyncMap,
      );
    } else {
      return emptySyncMap;
    }
  }

  async getSyncReaderById(id) {
    return await this.syncReaderService.getById(id);
  }

  async getSyncWriterById(id) {
    return await this.syncWriterService.getById(id);
  }

  
  async onLookup_SyncReader() {    
    const modalRef = this.modalService.open(SyncReaderLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: SyncReader = receivedEntry;
      this.syncReaders = [];
      this.syncReaders.push(recordSelected);
      this.syncMapForm.patchValue({ ['syncReaderID']: receivedEntry.id });
      this.syncMap.syncReader = receivedEntry;
      this.selectedSyncReader = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_SyncWriter() {
    const modalRef = this.modalService.open(SyncWriterLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: SyncWriter = receivedEntry;
      this.syncWriters = [];
      this.syncWriters.push(recordSelected);
      this.syncMapForm.patchValue({ ['syncWriterID']: receivedEntry.id });
      this.syncMap.syncWriter = receivedEntry;
      this.selectedSyncWriter = receivedEntry;
      modalRef.close('test');
    });
  }
    
  async loadData() {
    try {
      [this.syncMap, this.triggeringEntityTypes, this.queueTypes] = await Promise.all([
        this.getSyncMapById(),
        this.getSyncEntityTypes(),
        this.getQueueTypes()
      ]);

      if (this.syncMap.syncReaderID) {
        const currentSyncReader: SyncReader = await Promise.resolve(this.getSyncReaderById(this.syncMap.syncReaderID));
        this.syncReaders.push(currentSyncReader);
      }
      if (this.syncMap.syncWriterID) {
        const currentSyncWriter: SyncWriter = await Promise.resolve(this.getSyncWriterById(this.syncMap.syncWriterID));
        this.syncWriters.push(currentSyncWriter);
      }

      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.SyncReader:
        this.syncReaders = [];
        this.syncReaders = await (await this.syncReaderService.getList(params))
          .body;
        break;
      case Filters.SyncWriter:
        this.syncWriters = [];
        this.syncWriters = await (
          await this.syncWriterService.getList(params)
        ).body;
        break;
      default:
        break;
    }
  }

  onSelectBoxChanged(id: number, key: keyof SyncMap, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.syncQueue[key] = item;    
  }

}
