export interface SyncWriter {
  id: number;
  name: string;
  description: string;
  storedProcName: string;
}

export const emptySyncWriter: SyncWriter = {
  id: null,
  name: '',
  description: '',
  storedProcName: ''
};
