export interface SystemJobLog {
  id: number;
  name: string;
  beginDateTime: Date;
  endDateTime: Date;
  minutesToComplete: number;
  status: string;
  comment: string;
}

export const emptySystemJobLog: SystemJobLog = {
  id: null,
  name: '',
  beginDateTime: null,
  endDateTime: null,
  minutesToComplete: null,
  status: '',
  comment: ''
};
