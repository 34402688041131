import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SyncQueuesService } from '../../../services/sync-queues/sync-queues.service';
import { SyncQueue } from '../../../services/sync-queues/sync-queues.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { catchError } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'sync-queue-list',
  templateUrl: './sync-queue-list.component.html',
  styleUrls: ['./sync-queue-list.component.scss'],
})
export class SyncQueueListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private syncQueuesService: SyncQueuesService,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private datePipe: DatePipe,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });   
  }

  @Input() loading: boolean = true;
  @Input() syncQueues: SyncQueue[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input()
  mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: SyncQueue[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public queueTypeOptions: Lookup[];
  public entityTypeOptions: Lookup[];
  public queueStatusOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Entry Time',
      field: 'entryTime',
      width: 175,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Queue Type',
      field: 'queueType.name',
      width: 175,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Entity',
      field: 'entity.name',
      width: 175,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Record ID',
      field: 'recordID',
      width: 175,
      orderIndex: 3,
      hidden: false,
    },
    {
      title: 'Since Time',
      field: 'sinceTime',
      width: 175,
      orderIndex: 4,
      hidden: false,
    },
    {
      title: 'Status',
      field: 'status.name',
      width: 175,
      orderIndex: 5,
      hidden: false,
    },
    {
      title: 'Completed Time',
      field: 'completedTime',
      width: 175,
      orderIndex: 6,
      hidden: false,
    },
    {
      title: 'Error Message',
      field: 'errorMessage',
      width: 175,
      orderIndex: 7,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async getQueueTypeOptions() {
    const Filter_OptionSet = 'QueueType';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.queueTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "QueueType";
    defaultLookup.name = "(All)";
    this.queueTypeOptions.reverse();
    this.queueTypeOptions.push(defaultLookup);
    this.queueTypeOptions.reverse();
    return this.queueTypeOptions;
  }

  async getEntityTypeOptions() {
    const Filter_OptionSet = 'SyncEntityType';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.entityTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "SyncEntityType";
    defaultLookup.name = "(All)";
    this.entityTypeOptions.reverse();
    this.entityTypeOptions.push(defaultLookup);
    this.entityTypeOptions.reverse();
    return this.entityTypeOptions;
  }

  async getQueueStatusOptions() {
    const Filter_OptionSet = 'QueueStatus';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.queueStatusOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "QueueStatus";
    defaultLookup.name = "(All)";
    this.queueStatusOptions.reverse();
    this.queueStatusOptions.push(defaultLookup);
    this.queueStatusOptions.reverse();
    return this.queueStatusOptions;
  }
  
  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.syncQueuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }
   
  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('SyncQueueList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('SyncQueueList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('SyncQueueList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
    this.getQueueTypeOptions();
    this.getQueueStatusOptions();
    this.getEntityTypeOptions();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('SyncQueueList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }
  
  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }
  
  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'entryTime') {
          const Filter_EntryTime = this.datePipe.transform(myFilter.value, "MM/dd/yyyy");
          const Operand_EntryTime = myFilter.operator;
          const params = { ...this.query, Filter_EntryTime, Operand_EntryTime };
          this.query = params;
        }
        if (myFilter.field == 'queueType.name') {
          if (myFilter.value != '(All)') {
            const Filter_QueueType = myFilter.value;
            const Operand_QueueType = myFilter.operator;
            const params = { ...this.query, Filter_QueueType, Operand_QueueType };
            this.query = params;
          }
        }
        if (myFilter.field == 'syncEntityType.name') {
          if (myFilter.value != '(All)') {
            const Filter_Entity = myFilter.value;
            const Operand_Entity = myFilter.operator;
            const params = { ...this.query, Filter_Entity, Operand_Entity };
            this.query = params;
          }
        }
        if (myFilter.field == 'recordID') {
          const Filter_RecordID = myFilter.value == null ? '' : myFilter.value;
          const Operand_RecordID = myFilter.operator;
          const params = { ...this.query, Filter_RecordID, Operand_RecordID };
          this.query = params;
        }
        if (myFilter.field == 'startOn') {
          if (myFilter.value != null) {
            const Filter_StartOn = this.datePipe.transform(myFilter.value, "MM/dd/yyyy");
            const Operand_StartOn = myFilter.operator;
            const params = { ...this.query, Filter_StartOn, Operand_StartOn };
            this.query = params;
          }
          else {
            const Operand_StartOn = myFilter.operator;
            const params = { ...this.query, Operand_StartOn };
            this.query = params;
          }
        }
        if (myFilter.field == 'sinceTime') {
          if (myFilter.value != null) {
            const Filter_SinceTime = this.datePipe.transform(myFilter.value, "MM/dd/yyyy");
            const Operand_SinceTime = myFilter.operator;
            const params = { ...this.query, Filter_SinceTime, Operand_SinceTime };
            this.query = params;
          }
          else {
            const Operand_SinceTime = myFilter.operator;
            const params = { ...this.query, Operand_SinceTime };
            this.query = params;
          }
        }
        if (myFilter.field == 'queueStatus.name') {
          if (myFilter.value != '(All)') {
            const Filter_Status = myFilter.value;
            const Operand_Status = myFilter.operator;
            const params = { ...this.query, Filter_Status, Operand_Status };
            this.query = params;
          }
        }
        if (myFilter.field == 'completedTime') {          
          if (myFilter.value != null) {
            const Filter_CompletedTime = this.datePipe.transform(myFilter.value, "MM/dd/yyyy");
            const Operand_CompletedTime = myFilter.operator;
            const params = { ...this.query, Filter_CompletedTime, Operand_CompletedTime };
            this.query = params;
          }
          else {
              const Operand_CompletedTime = myFilter.operator;
              const params = { ...this.query, Operand_CompletedTime };
              this.query = params;
          }
        }
        if (myFilter.field == 'progressMessage') {
          const Filter_ProgressMessage = myFilter.value == null ? '' : myFilter.value;
          const Operand_ProgressMessage = myFilter.operator;
          const params = { ...this.query, Filter_ProgressMessage, Operand_ProgressMessage };
          this.query = params;
        }
        if (myFilter.field == 'errorMessage') {
          const Filter_ErrorMessage = myFilter.value == null ? '' : myFilter.value;
          const Operand_ErrorMessage = myFilter.operator;
          const params = { ...this.query, Filter_ErrorMessage, Operand_ErrorMessage };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.syncQueuesService.getList(this.query);
      this.syncQueues = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.syncQueues,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  edit(id: number) {
    this.router.navigate([`/price/sync-queue/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.syncQueuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }
  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }
  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

}
