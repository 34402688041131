import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SyncQueuesService } from '../../../services/sync-queues/sync-queues.service';
import { SyncQueue } from '../../../services/sync-queues/sync-queues.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { catchError } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, ExcelModule, GridComponent, ExcelComponent, FooterComponent, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { SyncWriterSingleComponent } from '../sync-writer-single/sync-writer-single.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { SyncWritersService } from '../../../services/sync-writers/sync-writers.service';
import { SyncWriter } from '../../../services/sync-writers/sync-writers.model';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-sync-writer-list',
  templateUrl: './sync-writer-list.component.html',
  styleUrls: ['./sync-writer-list.component.scss'],
})
export class SyncWriterListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private syncWritersService: SyncWritersService,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() syncWriters: SyncWriter[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input()
  mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: SyncWriter[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 175,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Description',
      field: 'description',
      width: 175,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Stored Procedure Name',
      field: 'storedProcName',
      width: 175,
      orderIndex: 2,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }
  
  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMSyncWriter", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully! Please refresh the grid to see results.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.syncWritersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('SyncWriterList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('SyncWriterList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('SyncWriterList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();  

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('SyncWriterList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.router.navigate(['/price/sync-writers/' + this.clickedRowItem.id]);
    }
  }

  //onEdit(syncWriter) {
  //  const modalRef = this.modalService.open(SyncWriterSingleComponent);
  //  modalRef.componentInstance.syncWriter = syncWriter;
  //  modalRef.componentInstance.idOfSyncWriter = syncWriter.id;
  //  modalRef.componentInstance.onFormSaved.subscribe(() => {
  //    this.loadListData();
  //    modalRef.close();
  //  });
  //}

  //onCreate() {
  //  const modalRef = this.modalService.open(SyncWriterSingleComponent);
  //  modalRef.componentInstance.onFormSaved.subscribe(() => {
  //    this.loadListData();
  //    modalRef.close();
  //  });
  //}

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'description') {
          const Filter_Description = myFilter.value;
          const Operand_Description = myFilter.operator;
          const params = { ...this.query, Filter_Description, Operand_Description };
          this.query = params;
        }
        if (myFilter.field == 'storedProcName') {
          const Filter_StoredProcName = myFilter.value;
          const Operand_StoredProcName = myFilter.operator;
          const params = { ...this.query, Filter_StoredProcName, Operand_StoredProcName };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.syncWritersService.getList(this.query);
      this.syncWriters = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.syncWriters,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  edit(id: number) {
    this.router.navigate([`/price/sync-writer/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.syncWritersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

}
