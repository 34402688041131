import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuantityBreaksService } from '../../../services/quantity-breaks/quantity-breaks.service';
import {
  QuantityBreak,
  emptyQuantityBreak,
} from '../../../services/quantity-breaks/quantity-breaks.model';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import {
  getPaginationHeader,
  mockedData,
} from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { numberSymbols } from '@progress/kendo-angular-intl';

enum Filters {
  Name = 1,
  RoundingDirectionOption,
  RoundingToOption,
  RoundingAmount,
}

@Component({
  selector: 'app-quantity-break-single',
  templateUrl: './quantity-break-single.component.html',
  styleUrls: ['./quantity-break-single.component.scss'],
})
export class QuantityBreakSingleComponent implements OnInit {
  filters = Filters;
  @Input() idOfQuantityBreak: number | null = null;
  @Input() idOfPriceSheetDetail: number | null = null;
  quantityBreak: QuantityBreak = emptyQuantityBreak;
  loading: boolean = true;
  quantityBreakForm: FormGroup;
  roundingDirectionOptions: Lookup[] = [];
  roundingToOptions: Lookup[] = [];
  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  modalReference: any;
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  existingQuantityBreaks: QuantityBreak[] = [];

  constructor(
    private route: ActivatedRoute,
    private quantityBreaksService: QuantityBreaksService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
  ) {
    // this.route.params.subscribe(params => {
    //   if (params.id && params.id === 'new') { return; }
    //   this.idOfQuantityBreak = params.id;
    //   this.loadData();
    // });
    // this.buildForm();
  }

  ngOnInit(): void {
    if (this.idOfQuantityBreak) {
      this.loadData();
    }
    if (this.idOfPriceSheetDetail) {
      this.quantityBreak.priceSheetDetailID = this.idOfPriceSheetDetail;
    }
    this.buildForm();
    this.updateForm();
  }

  buildForm() {
    this.quantityBreakForm = new FormGroup({
      priceSheetDetailID: new FormControl(''),
      quantityFromCount: new FormControl('', Validators.required),
      priceAmount: new FormControl('', Validators.required),
    });

    this.quantityBreakForm.valueChanges.subscribe((status) => {
      this.onFormChanged.emit(true);
    });

  }

  get f() {
    return this.quantityBreakForm.controls;
  }

  updateForm() {
    this.quantityBreakForm.patchValue({ ...this.quantityBreak });
    this.onFormChanged.emit(false);
  }

  clearPrefill() {
    setTimeout(() => {
      this.roundingDirectionOptions = [];
      this.roundingToOptions = [];
    });
  }

  onClose(message) {
    this.activeModal.close(message);
  }

  async submit() {
    this.quantityBreakForm.markAllAsTouched();
    this.submitted = true;
    
    if (this.quantityBreakForm.invalid) {
      return window.scrollTo(0, 0);
    }

    // Check for exisitng record with same Quantity From  //TODO
    //const quantityBreak: QuantityBreak = this.quantityBreakForm.value;
    //const existingQuantityBreak = await this.quantityBreaksService
    //  .findByQuantityFromCount(quantityBreak.quantityFromCount)
    //  .toPromise();
    //if (existingQuantityBreak) {
    //  alert('Error: A record with the same quantityFromCount already exists.');
    //  return;
    //} 

    const data = { ...this.quantityBreak, ...this.quantityBreakForm.value };

    if (this.quantityBreak.id) {
      await this.quantityBreaksService.update(this.quantityBreak.id, data);
    } else {
      await this.quantityBreaksService.create(this.quantityBreakForm.value);
    }
    this.onFormSaved.emit(false);
  }
 
  async getQuantityBreakById() {
    if (this.idOfQuantityBreak) {
      return await this.quantityBreaksService.getById(this.idOfQuantityBreak);
    } else {
      return emptyQuantityBreak;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [this.quantityBreak] = await Promise.all([this.getQuantityBreakById()]);
      this.prepareData();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  prepareData() {
   this.updateForm();
    this.clearPrefill();
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.RoundingDirectionOption:
        this.roundingDirectionOptions = await this.lookupService.getList(
          params,
        );
        break;
      case Filters.RoundingToOption:
        this.roundingToOptions = await this.lookupService.getList(params);
        break;
      default:
        break;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  };

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  };

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
