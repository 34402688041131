import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef, } from '@angular/core';
import { ReportsService } from '../../../services/reports/reports.service';
import { Report, emptyReport } from '../../../services/reports/reports.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { v4 as uuidv4 } from 'uuid';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, ExcelModule, GridComponent, ExcelComponent, FooterComponent, RowArgs, } from '@progress/kendo-angular-grid';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';

@Component({
  selector: 'app-report-single',
  templateUrl: './report-single.component.html',
  styleUrls: ['./report-single.component.scss'],
})
export class ReportSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfReport: number | null = null;
  @Input() report: Report = emptyReport;
  @Input() query = { PageSize: 10, PageNumber: 1 };
  @Output() onFormSaved = new EventEmitter<Boolean>();

  reportForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;
  public isRocktonAdmin: boolean;
  public gridView: GridDataResult;
  public skip = 0;

  constructor(
    private reportsService: ReportsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper,
    private utilitiesService: UtilitiesService,
    private snack: MySnackBarService,
  ) {
  }

  ngOnInit(): void {
    if (this.idOfReport) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();

    this.isRocktonAdmin = StorageService.IsRocktonAdmin();
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.reportForm.disable();
    }
  }

  buildForm() {
    this.reportForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ranOn: new FormControl(''),
      sqlScriptText: new FormControl('')
    });
    this.reportForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.reportForm.controls;
  }

  updateForm() {
    this.reportForm.patchValue({ ...this.report });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onTabSelect(e) {
    if (e.title === 'Results' && this.isFormChanged) {
      e.preventDefault(); // Prevent tab change
      //this.notificationHelper.showStatus('Please save changes to continue', "success");
    }
  }

  onClick_Save() {
    this.saveRecord();
  }

  async onClick_SaveClose() {
    const success = await this.saveRecord();
    if (success == true) {
      this.activeModal.close();
    }
  }

  async saveRecord(): Promise<boolean> {
    this.reportForm.markAllAsTouched();
    this.submitted = true;
    if (this.reportForm.invalid) {
      window.scrollTo(0, 0);
      return false;
    }
    const data = {
      ...this.report,
      ...this.reportForm.getRawValue(), // reportForm.value does not include disabled controls.
    };
    // Check to see if Report Name already exists.
    if ((await this.validation_CheckForExistingName()) === true) {
      this.notificationHelper.showStatusOnDialog('Report Name already exists!', 'error', this.container);
      return false;
    }

    if (this.report.id) {
      try {
        const response: any = await this.reportsService.update(
          this.report.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.reportsService.create(
          this.reportForm.getRawValue(), // reportForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        return false;
      }
    }
    //this.onFormSaved.emit(false);
    return true;
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.reportForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingReports: Report[] = await (
      await this.reportsService.getList(params)
    ).body;
    if (existingReports.length > 0) {
      if (this.report.id) {
        for (const key in existingReports) {
          const currentReport: Report = existingReports[key];
          if (currentReport.id != this.report.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async getReportById() {
    if (this.idOfReport) {
      return await this.reportsService.getById(this.idOfReport);
    } else {
      return emptyReport;
    }
  }

  async loadData() {
    try {
      [this.report] = await Promise.all([this.getReportById()]);
      const jsonData = JSON.parse(this.report.resultSet);
      const recordCount = jsonData.length;
      const startIndex = (this.query.PageNumber - 1) * this.query.PageSize;
      const pageData = jsonData.slice(startIndex, startIndex + this.query.PageSize)
      this.gridView = {
        data: pageData,
        total: recordCount,
      };
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadData();
  }

  onClickRefresh() {
    this.loadData();
  }

  async onClickCalculate() {
    try {
      try {
        const params = [
          {
            Name: "@ReportID",
            Value: this.idOfReport
          }
        ];
        const paramList = JSON.stringify(params);
        const response: any = await this.utilitiesService.callStoredProc('usp_RPMProcessReport', paramList);
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Report regenerated successfully.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
    finally { }
    this.loadData();
  }

  async onClickExport(grid: GridComponent, excelComponent: ExcelComponent) {
    grid.saveAsExcel();
  }

}
