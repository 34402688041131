import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { SyncReadersService } from '../../../services/sync-readers/sync-readers.service';
import { SyncReader, emptySyncReader } from '../../../services/sync-readers/sync-readers.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ActivatedRoute, Router } from '@angular/router';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { SyncReaderColumnSubListComponent } from '../../sync-reader-columns/sync-reader-column-sublist/sync-reader-column-sublist.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Lookup } from '../../../services/lookups/lookups.model';
import { CopyService } from '../../../services/copy/copy.service';

enum Filters {
  AttributeDataType = 1,
}

@Component({
  selector: 'app-sync-reader-single',
  templateUrl: './sync-reader-single.component.html',
  styleUrls: ['./sync-reader-single.component.scss'],
})
export class SyncReaderSingleComponent implements OnInit {
  @ViewChild('subgrid') syncReaderColumnSubGrid: SyncReaderColumnSubListComponent;
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncReader: number | null = null;
  @Input() syncReader: SyncReader = emptySyncReader;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  filters = Filters;
  myResponse: string;
  syncReaderForm: FormGroup;  
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  entryTime: Date = new Date();
  sinceTime: Date = new Date();
  startOn: Date = new Date();
  public isAdmin: boolean;
  goBackForce = false;
  nextURL = '';

  constructor(
    private route: ActivatedRoute,
    private syncReadersService: SyncReadersService,    
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private snack: MySnackBarService,
    private router: Router,
    private copyService: CopyService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.syncReader = emptySyncReader;
        this.idOfSyncReader = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfSyncReader = params.id;
      this.loadData();
    });
    this.buildForm();    
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (this.idOfSyncReader) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncReaderForm = new FormGroup({      
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      query: new FormControl('', Validators.required),
      pageSize: new FormControl(1000, Validators.required)
    });
    this.syncReaderForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });   
  }

  async onClickCopy() {
    try {      
      const response: any = await this.copyService.performCopy(null, "RPMSyncReader", this.syncReader.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  get disableDeleteBtn() {
    if (this.syncReader && this.syncReader.id) {
      return false;
    }
    else {
      return true;
    }
  }

  get f() {
    return this.syncReaderForm.controls;
  }
  updateForm() {
    this.syncReaderForm.patchValue({ ...this.syncReader });
    this.isFormChanged = false;
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  //onClick_Close(message) {
  //  if (this.isFormChanged) {
  //    this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
  //    this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
  //    this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
  //  } else {
  //    this.activeModal.close(message);
  //  }
  //}

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/sync-readers/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/sync-readers/new']);
      });
    }
  }

  onClick_Save() {
    this.saveRecord(null);
  }

  onClick_SaveClose() {
    this.saveRecord("Close");
  }

  onClick_SaveNew() {
    this.saveRecord("New");
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.syncReader.id);
      await this.syncReadersService
        .removeByIds(ids)
        .then((showSuccess: VoidFunction) => {
          this.snack.openSnackBar(
            'Record(s) deleted successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          modal.close();
          this.router.navigate(['/price/sync-readers']);
        }
        )
        .catch((err: HttpErrorResponse) => {
          this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
          modal.close();
        });
  }

  async saveRecord(action) {
    this.syncReaderForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncReaderForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncReader,
      ...this.syncReaderForm.value,
    };
    if (this.syncReader.id) {
      try {
        const response: any = await this.syncReadersService.update(
          this.syncReader.id,
          data,
        );        
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-readers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-readers/new']);
            });
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
         const response: any = await this.syncReadersService.create(
           this.syncReaderForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-readers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-readers/new']);
            });
          }
          else {
            this.syncReader = response.body;
            this.router.navigate(['/price/sync-readers/', this.syncReader.id]);
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);

  }
     
  async getSyncReaderById() {
    if (this.idOfSyncReader) {
      return await this.syncReadersService.getById(
        this.idOfSyncReader,
      );
    } else {
      return emptySyncReader;
    }
  }
  async loadData() {
    try {
      [this.syncReader] = await Promise.all([
        this.getSyncReaderById(),
      ]);      
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }
  
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
