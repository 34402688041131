<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/SyncMap.png" alt="Sync Maps"
    style="padding-right: 5px;">
  Sync Maps
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
    <ng-template kendoGridToolbarTemplate>
        <kendo-buttongroup>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
                    imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
                Refresh
            </button>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
                    imageUrl="../../../../assets/images/Add.png" (click)="onCreate()"
                    title="Add New">
                Add
                New
            </button>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn"
                    imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">
                Edit
            </button>
            <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
                    imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()"
                    title="Delete Selected">
                Delete
            </button>
        </kendo-buttongroup>
        <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
    </ng-template>
    <kendo-grid-column field="name" title="Name" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="description" title="Description" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
                <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
                <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="queueType.name" title="Queue Type" width="275"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <sync-map-queue-type-filter [filter]="filter"
                                        [data]="queueTypeOptions"
                                        textField="name"
                                        valueField="name">
            </sync-map-queue-type-filter>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="triggeringEntity.name" title="Triggering Entity" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <sync-map-entity-filter [filter]="filter"
                                    [data]="entityTypeOptions"
                                    textField="name"
                                    valueField="name">
            </sync-map-entity-filter>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="rank" title="Rank" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-lt-operator></kendo-filter-lt-operator>
                <kendo-filter-lte-operator></kendo-filter-lte-operator>
                <kendo-filter-gt-operator></kendo-filter-gt-operator>
                <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-numeric-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isActive" filter="boolean" title="Active" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
        </kendo-grid-messages>
        <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.isActive ? 'Yes' : 'No'}} </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="syncReader.name" title="Sync Reader" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="syncWriter.name" title="Sync Writer" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="filter" title="Filter" width="175"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
                <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
                <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
