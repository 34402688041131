import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { SystemJobParametersService } from '../../../services/system-job-parameters/system-job-parameters.service';
import { SystemJobParameter, emptySystemJobParameter } from '../../../services/system-job-parameters/system-job-parameters.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
//import { Customer } from 'src/app/services/customers/customers.model';
//import { CustomersService } from '../../../services/customers/customers.service';
//import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Catalog } from 'src/app/services/catalogs/catalog.model';
import { CatalogLookupComponent } from '../../catalogs/catalog-lookup/catalog-lookup.component';
import { CatalogsService } from '../../../services/catalogs/catalog.service';
import { CatalogSheet } from '../../../services/catalog-sheets/catalog-sheets.model';
import { CatalogSheetLookupComponent } from '../../catalog-sheets/catalog-sheet-lookup/catalog-sheet-lookup.component';
import { CatalogSheetsService } from '../../../services/catalog-sheets/catalog-sheets.service';

enum Filters {
  //Customer,
  Catalog,
  CatalogSheet,
}
@Component({
  selector: 'app-system-job-parameter-single',
  templateUrl: './system-job-parameter-single.component.html',
  styleUrls: ['./system-job-parameter-single.component.scss'],
})
export class SystemJobParameterSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSystemJobParameter: number | null = null;
  @Input() systemJobParameter: SystemJobParameter = emptySystemJobParameter;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  dataTypeID: number;
  dataTypes: Lookup[] = [];
  entityTypeID: number;
  entityTypes: Lookup[] = [];
  entityRecords: any[] = [];

  filters = Filters;
  systemJobParameterForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;
  public isRocktonAdmin: boolean;
  //customers: Customer[] = [];
  catalogs: Catalog[] = [];
  catalogSheets: CatalogSheet[] = [];
  syncEntityTypeID: number;
  syncEntityTypes: Lookup[] = [];
  queueTypeID: number;
  queueTypes: Lookup[] = [];
  queueStatusID: number;
  queueStatuses: Lookup[] = [];
  lookupListOptionSet: string;
  lookupListOptionSetValues: Lookup[] = [];
  //pagination_customers: any = { customers: mockedData };
  pagination_catalogs: any = { catalogs: mockedData };
  pagination_catalogSheets: any = { catalogSheets: mockedData };

  constructor(
    private systemJobParametersService: SystemJobParametersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private notificationHelper: NotificationHelper,
    //private customersService: CustomersService,
    private catalogsService: CatalogsService,
    private catalogSheetsService: CatalogSheetsService,
    private lookupService: LookupsService,
  ) {
  }

  ngOnInit(): void {
    //console.log('idOfSystemJobParameter is: ' + this.idOfSystemJobParameter)
    if (this.idOfSystemJobParameter) {
      this.loadData();
    }
    this.buildForm();
    this.subscribeToFormChanges();
    this.updateForm();

    //if (!this.idOfSystemJobParameter) {
    //  this.dataTypeChanged(17); //default to string
    //}

    this.isRocktonAdmin = StorageService.IsRocktonAdmin();
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.systemJobParameterForm.disable();
    }
  }

  buildForm() {
    this.systemJobParameterForm = new FormGroup({
      sequenceNumber: new FormControl(''),
      name: new FormControl(''),
      isSystemDefined: new FormControl(''),
      dataTypeID: new FormControl(),
      lookupListOptionSet: new FormControl(''),
      entityTypeID: new FormControl(''),
      entityRecordID: new FormControl(''),
      value: new FormControl(''),
      sqlScriptText: new FormControl(''),
      //these fields are not in the table
      genericRecordID: new FormControl(''),
      //customerID: new FormControl(''),
      catalogID: new FormControl(''),
      catalogSheetID: new FormControl(''),
      syncEntityTypeID: new FormControl(''),
      queueTypeID: new FormControl(''),
      queueStatusID: new FormControl(''),
    });
    this.getDataTypes();
    this.getEntityTypes();
    this.getSyncEntityTypes();
    this.getQueueTypes();
    this.getQueueStatuses();
    this.getLookupListOptionSetValues();
    this.systemJobParameterForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  subscribeToFormChanges() {
    this.systemJobParameterForm.get('catalogID').valueChanges.subscribe(value => {
      this.catalogIDChanged(value);
    });
    this.systemJobParameterForm.get('catalogSheetID').valueChanges.subscribe(value => {
      this.catalogSheetIDChanged(value);
    });
  }

  get f() {
    return this.systemJobParameterForm.controls;
  }

  updateForm() {
    this.systemJobParameterForm.patchValue({ ...this.systemJobParameter });
    this.isFormChanged = false;
  }

  onClick_Save() {
    this.saveRecord();
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  async saveRecord() {
    this.systemJobParameterForm.markAllAsTouched();
    this.submitted = true;

    if (this.systemJobParameterForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.systemJobParameter,
      ...this.systemJobParameterForm.getRawValue(), // systemJobParameterForm.value does not include disabled controls.
    };

    if (this.systemJobParameter.id) {
      try {
        const response: any = await this.systemJobParametersService.update(
          this.systemJobParameter.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.systemJobParametersService.create(
          this.systemJobParameterForm.getRawValue(), // systemJobParameterForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }

  async getSystemJobParameterById() {
    if (this.idOfSystemJobParameter) {
      return await this.systemJobParametersService.getById(this.idOfSystemJobParameter);
    } else {
      return emptySystemJobParameter;
    }
  }

  async loadData() {
    try {
      [this.systemJobParameter] = await Promise.all([this.getSystemJobParameterById()]);

      // Customers
      //if ((this.systemJobParameter.entityRecordID) && (this.systemJobParameter.entityTypeID == 68)) {
      //  const currentRecord: Customer = await Promise.resolve(this.getCustomerById(this.systemJobParameter.entityRecordID));
      //  this.customers.push(currentRecord);
      //}

      // Catalogs
      if ((this.systemJobParameter.entityRecordID) && (this.systemJobParameter.entityTypeID == 136)) {
        const currentRecord: Catalog = await Promise.resolve(this.getCatalogById(this.systemJobParameter.entityRecordID));
        this.catalogs.push(currentRecord);
        this.systemJobParameterForm.get('catalogID').patchValue(currentRecord.id);
      }

      // Catalog Sheets
      if ((this.systemJobParameter.entityRecordID) && (this.systemJobParameter.entityTypeID == 137)) {
        const currentRecord: CatalogSheet = await Promise.resolve(this.getCatalogSheetById(this.systemJobParameter.entityRecordID));
        this.catalogSheets.push(currentRecord);
        this.systemJobParameterForm.get('catalogSheetID').patchValue(currentRecord.id);
      }

      //SyncEntityType
      if ((this.systemJobParameter.entityRecordID) && (this.systemJobParameter.lookupListOptionSet == 'SyncEntityType')) {
        this.systemJobParameterForm.get('syncEntityTypeID').patchValue(this.systemJobParameter.entityRecordID);
        //this.systemJobParameterForm.get('entityRecordID').patchValue(this.systemJobParameter.entityRecordID);
      }

      //QueueType
      if ((this.systemJobParameter.entityRecordID) && (this.systemJobParameter.lookupListOptionSet == 'QueueType')) {
        this.systemJobParameterForm.get('queueTypeID').patchValue(this.systemJobParameter.entityRecordID);
        //this.systemJobParameterForm.get('entityRecordID').patchValue(this.systemJobParameter.entityRecordID);
      }

      //QueueStatus
      if ((this.systemJobParameter.entityRecordID) && (this.systemJobParameter.lookupListOptionSet == 'QueueStatus')) {
        this.systemJobParameterForm.get('queueStatusID').patchValue(this.systemJobParameter.entityRecordID);
        //this.systemJobParameterForm.get('entityRecordID').patchValue(this.systemJobParameter.entityRecordID);
      }

      this.updateForm();  //this gets called by Init, why is it here?
    } catch (e) {
    } finally {
    }
    if (this.systemJobParameter.dataTypeID != null) {
      this.dataTypeChanged(this.systemJobParameter.dataTypeID);
    }
    if (this.systemJobParameter.entityTypeID != null) {
      this.entityTypeChanged(this.systemJobParameter.entityTypeID);
    }
    if (this.systemJobParameter.lookupListOptionSet != null) {
      this.lookupListOptionSetChanged2(this.systemJobParameter.lookupListOptionSet);
    }
  }

  async getDataTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'AttributeDataType';
    const params = { Filter_OptionSet, PageSize };
    this.dataTypes = await this.lookupService.getList(params);
    return this.dataTypes;
  }

  async getEntityTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'JobParameterEntityType';
    const params = { Filter_OptionSet, PageSize };
    this.entityTypes = await this.lookupService.getList(params);
    return this.entityTypes;
  }

  async getSyncEntityTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'SyncEntityType';
    const params = { Filter_OptionSet, PageSize };
    this.syncEntityTypes = await this.lookupService.getList(params);
    return this.syncEntityTypes;
  }

  async getQueueTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'QueueType';
    const params = { Filter_OptionSet, PageSize };
    this.queueTypes = await this.lookupService.getList(params);
    return this.queueTypes;
  }

  async getQueueStatuses() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'QueueStatus';
    const params = { Filter_OptionSet, PageSize };
    this.queueStatuses = await this.lookupService.getList(params);
    return this.queueStatuses;
  }

  async getLookupListOptionSetValues() {
    if (this.lookupListOptionSet) {
      const PageSize = StorageService.PageSize() ?? 50;
      const Filter_OptionSet = this.lookupListOptionSet;
      const params = { Filter_OptionSet, PageSize };
      this.lookupListOptionSetValues = await this.lookupService.getList(params);
      return this.lookupListOptionSetValues;
    }
    else {
      return;
    }
  }

  dataTypeChanged(dataTypeID) {
    this.dataTypeID = dataTypeID;

    this.systemJobParameterForm.get('entityTypeID').disable();
    this.systemJobParameterForm.get('entityTypeID').clearValidators();
    this.systemJobParameterForm.get('lookupListOptionSet').disable();
    this.systemJobParameterForm.get('lookupListOptionSet').clearValidators();
    this.systemJobParameterForm.get('syncEntityTypeID').disable();
    this.systemJobParameterForm.get('syncEntityTypeID').clearValidators();
    this.systemJobParameterForm.get('queueTypeID').disable();
    this.systemJobParameterForm.get('queueTypeID').clearValidators();
    this.systemJobParameterForm.get('queueStatusID').disable();
    this.systemJobParameterForm.get('queueStatusID').clearValidators();
    this.systemJobParameterForm.get('catalogID').disable();
    this.systemJobParameterForm.get('catalogID').clearValidators();
    this.systemJobParameterForm.get('catalogSheetID').disable();
    this.systemJobParameterForm.get('catalogSheetID').clearValidators();
    const valueControl = this.systemJobParameterForm.get('value');
    valueControl.clearValidators();

    switch (dataTypeID) {
      case 13: //Checkbox
        valueControl.setValidators(Validators.pattern('^[01]$'));
        valueControl.updateValueAndValidity();
        break;
      case 14: //Number
        valueControl.setValidators(Validators.pattern('^-?\\d+(\\.\\d+)?$'));
        valueControl.updateValueAndValidity();
        break;
      case 15: //Lookup
        this.systemJobParameterForm.get('entityTypeID').setValidators(Validators.required);
        this.systemJobParameterForm.get('entityTypeID').updateValueAndValidity();
        this.systemJobParameterForm.get('entityTypeID').enable();
        break;
      case 16: //Date
        //valueControl.setValidators(Validators.pattern('\\d{4}-\\d{2}-\\d{2}')); // Basic YYYY-MM-DD pattern
        valueControl.setValidators(Validators.pattern('^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\\d\\d$'));
        valueControl.updateValueAndValidity();
        break;
      case 17: //String
        break;
      case 134: //Text
        break;
      case 135: //Time
        valueControl.setValidators(Validators.pattern('^([01]?[0-9]|2[0-3]):([0-5][0-9])$'));
        valueControl.updateValueAndValidity();
        break;
      case 140: //List Lookup
        this.systemJobParameterForm.get('lookupListOptionSet').setValidators(Validators.required);
        this.systemJobParameterForm.get('lookupListOptionSet').updateValueAndValidity();
        this.systemJobParameterForm.get('lookupListOptionSet').enable();
        break;
    }
  }

  entityTypeChanged(entityTypeID) {
    this.entityTypeID = entityTypeID;
    this.systemJobParameterForm.get('catalogID').disable();
    this.systemJobParameterForm.get('catalogID').clearValidators();
    this.systemJobParameterForm.get('catalogSheetID').disable();
    this.systemJobParameterForm.get('catalogSheetID').clearValidators();
    switch (entityTypeID) {
      case 136: //Catalog
        this.systemJobParameterForm.get('catalogID').setValidators(Validators.required);
        this.systemJobParameterForm.get('catalogID').updateValueAndValidity();
        this.systemJobParameterForm.get('catalogID').enable();
        break;
      case 137: //Catalog Sheet
        this.systemJobParameterForm.get('catalogSheetID').setValidators(Validators.required);
        this.systemJobParameterForm.get('catalogSheetID').updateValueAndValidity();
        this.systemJobParameterForm.get('catalogSheetID').enable();
        break;
    }
  }

  syncEntityTypeChanged(syncEntityTypeID) {
    this.syncEntityTypeID = syncEntityTypeID;
    this.systemJobParameter.entityRecordID = syncEntityTypeID;
    this.systemJobParameterForm.get('entityRecordID').patchValue(syncEntityTypeID);
    const selectedRecord = this.syncEntityTypes.find(record => record.id === syncEntityTypeID);
    this.systemJobParameterForm.get('value').patchValue(selectedRecord.name);
  }

  queueTypeChanged(queueTypeID) {
    this.queueTypeID = queueTypeID;
    this.systemJobParameter.entityRecordID = queueTypeID;
    this.systemJobParameterForm.get('entityRecordID').patchValue(queueTypeID);
    const selectedRecord = this.queueTypes.find(record => record.id === queueTypeID);
    this.systemJobParameterForm.get('value').patchValue(selectedRecord.name);
  }

  queueStatusChanged(queueStatusID) {
    this.queueStatusID = queueStatusID;
    this.systemJobParameter.entityRecordID = queueStatusID;
    this.systemJobParameterForm.get('entityRecordID').patchValue(queueStatusID);
    const selectedRecord = this.queueStatuses.find(record => record.id === queueStatusID);
    this.systemJobParameterForm.get('value').patchValue(selectedRecord.name);
  }

  lookupListOptionSetChanged1(event: any) {
    this.lookupListOptionSetChanged2(event.target.value);
  }

  lookupListOptionSetChanged2(lookupListOptionSetValue) {
    this.lookupListOptionSet = lookupListOptionSetValue;
    this.systemJobParameterForm.get('syncEntityTypeID').disable();
    this.systemJobParameterForm.get('syncEntityTypeID').clearValidators();
    this.systemJobParameterForm.get('queueTypeID').disable();
    this.systemJobParameterForm.get('queueTypeID').clearValidators();
    this.systemJobParameterForm.get('queueStatusID').disable();
    this.systemJobParameterForm.get('queueStatusID').clearValidators();
    switch (this.lookupListOptionSet) {
      case 'SyncEntityType':
        this.systemJobParameterForm.get('syncEntityTypeID').setValidators(Validators.required);
        this.systemJobParameterForm.get('syncEntityTypeID').updateValueAndValidity();
        this.systemJobParameterForm.get('syncEntityTypeID').enable();
        break;
      case 'QueueType':
        this.systemJobParameterForm.get('queueTypeID').setValidators(Validators.required);
        this.systemJobParameterForm.get('queueTypeID').updateValueAndValidity();
        this.systemJobParameterForm.get('queueTypeID').enable();
        break;
      case 'QueueStatus':
        this.systemJobParameterForm.get('queueStatusID').setValidators(Validators.required);
        this.systemJobParameterForm.get('queueStatusID').updateValueAndValidity();
        this.systemJobParameterForm.get('queueStatusID').enable();
        break;
    }
  }

  catalogIDChanged(catalogID: any) {
    //need to make sure to only run this if datatype and entityType are correct
    if ((this.dataTypeID == 15) && (this.entityTypeID == 136)) {
      this.systemJobParameter.entityRecordID = catalogID;
      this.systemJobParameterForm.get('entityRecordID').patchValue(catalogID);
      const selectedCatalog = this.catalogs.find(catalog => catalog.id === catalogID);
      this.systemJobParameterForm.get('value').patchValue(selectedCatalog.name);
    }
  }

  catalogSheetIDChanged(catalogSheetID: any) {
    if ((this.dataTypeID == 15) && (this.entityTypeID == 137)) {
      this.systemJobParameter.entityRecordID = catalogSheetID;
      this.systemJobParameterForm.get('entityRecordID').patchValue(catalogSheetID);
      const selectedCatalogSheet = this.catalogSheets.find(catalogSheet => catalogSheet.id === catalogSheetID);
      this.systemJobParameterForm.get('value').patchValue(selectedCatalogSheet.name);
    }
  }

  //async getCustomers(params) {
  //  try {
  //    const resp = await this.customersService.getList({
  //      ...params,
  //    });
  //    // @ts-ignore
  //    this.customers = resp.body;
  //    this.pagination_customers.customers = getPaginationHeader(
  //      // @ts-ignore
  //      resp.headers,
  //    );
  //  } catch (e) {
  //    this.customers = [];
  //  }
  //  return this.customers;
  //}

  async getCatalogs(params) {
    try {
      const resp = await this.catalogsService.getList({
        ...params,
      });
      // @ts-ignore
      this.catalogs = resp.body;
      this.pagination_catalogs.catalogs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.catalogs = [];
    }
    return this.catalogs;
  }

  async getCatalogSheets(params) {
    try {
      const resp = await this.catalogSheetsService.getList({
        ...params,
      });
      // @ts-ignore
      this.catalogSheets = resp.body;
      this.pagination_catalogSheets.catalogSheets = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.catalogSheets = [];
    }
    return this.catalogSheets;
  }

  //async onLookup_Customer() {
  //  const modalRef = this.modalService.open(CustomerLookupComponent, {});
  //  modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
  //    const recordSelected: Customer = receivedEntry;
  //    this.customers = [];
  //    this.customers.push(recordSelected);
  //    this.systemJobParameterForm.patchValue({ ['customerID']: receivedEntry.id });
  //    this.systemJobParameter.entityRecordID = receivedEntry.id; //special
  //    modalRef.close('test');
  //  });
  //}

  async onLookup_Catalog() {
    const modalRef = this.modalService.open(CatalogLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Catalog = receivedEntry;
      this.catalogs = [];
      this.catalogs.push(recordSelected);
      this.systemJobParameterForm.patchValue({ ['catalogID']: receivedEntry.id });
      modalRef.close('test');
    });
  }

  async onLookup_CatalogSheet() {
    const modalRef = this.modalService.open(CatalogSheetLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CatalogSheet = receivedEntry;
      this.catalogSheets = [];
      this.catalogSheets.push(recordSelected);
      this.systemJobParameterForm.patchValue({ ['catalogSheetID']: receivedEntry.id });
      modalRef.close('test');
    });
  }

  //async getCustomerById(id) {
  //  return await this.customersService.getById(id);
  //}

  async getCatalogById(id) {
    return await this.catalogsService.getById(id);
  }

  async getCatalogSheetById(id) {
    return await this.catalogSheetsService.getById(id);
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      //case Filters.Customer:
      //  // @ts-ignore
      //  this.customers = this.getCustomers(params);
      //  break;
      case Filters.Catalog:
        // @ts-ignore
        this.catalogs = this.getCatalogs(params);
        break;
      case Filters.CatalogSheet:
        // @ts-ignore
        this.catalogSheets = this.getCatalogSheets(params);
        break;
      default:
        break;
    }
  }
}
