import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChild, ElementRef, AfterViewInit, } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../../../services/reports/reports.service';
import { Report } from '../../../services/reports/reports.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader, } from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, ExcelModule, GridComponent, ExcelComponent, FooterComponent, RowArgs, } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { ReportSingleComponent } from '../report-single/report-single.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';
import { PricingFilterValueCollection } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.model';
import { PricingFilterValueCollectionsService } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private reportsService: ReportsService,
    private pricingFilterValuesService: PricingFilterValuesService,
    private pricingFilterValueCollectionsService: PricingFilterValueCollectionsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() reports: Report[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: Report[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public isAdmin: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 300,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Ran On',
      field: 'ranOn',
      width: 200,
      orderIndex: 0,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.reportsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });
    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }
  
  onResetGridClick() {
    StorageService.removeColumnSettings('ReportList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('ReportList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('ReportList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save columns configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('ReportList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.isAdmin = StorageService.IsAdmin();
    this.loadListData();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMReport');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }
    
  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.onEdit(this.clickedRowItem);
        //this.router.navigate(['/price/report/' + this.clickedRowItem.id]);
      }
    }
  }

  onEdit(report) {
    const modalRef = this.modalService.open(ReportSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "ReportModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.report = report;
    modalRef.componentInstance.idOfReport = report.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  onCreate() {
    const modalRef = this.modalService.open(
      ReportSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "ReportModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'erpid') {
          const Filter_ERP = myFilter.value;
          const Operand_ERP = myFilter.operator;
          const params = { ...this.query, Filter_ERP, Operand_ERP };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.reportsService.getList(this.query);
      this.reports = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.reports,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  edit(id: number) {
    this.router.navigate([`/price/report/${id}`]);
  }
  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.reportsService.getExportList(this.query);
  };

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.reportsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async isUsedOnPricingFilter(values: number[], Filter_EntityName): Promise<boolean> {
    var isUsedOnPricingFilter: boolean = false;

    const PageSize = StorageService.PageSize() ?? 50;
    const Operand_Value = 'eq';
    const Operand_EntityName = 'eq';

    for (var value of values) {
      let Filter_Value: string = value.toString();
      const params = { Filter_Value, Operand_Value, Filter_EntityName, Operand_EntityName, PageSize };
      const existingPricingFilterValues: PricingFilterValue[] = await (
        await this.pricingFilterValuesService.getList(params)
      ).body;
      if (existingPricingFilterValues.length > 0) {
        isUsedOnPricingFilter = true;
        break;
      }
      else {
        //Need to check Pricing Filter Value Collections
        const params = { Filter_Value, Operand_Value, PageSize };
        const existingPricingFilterValueCollections: PricingFilterValueCollection[] = await (
          await this.pricingFilterValueCollectionsService.getList(params)
        ).body;
        if (existingPricingFilterValueCollections.length > 0) {
          for (var pricingFilterValueCollection of existingPricingFilterValueCollections) {
            let currentPFV: PricingFilterValue = await this.pricingFilterValuesService.getById(pricingFilterValueCollection.pricingFilterValueID);
            if (currentPFV.entityID = 72) { //Report
              isUsedOnPricingFilter = true;
              break;
            }
          }
        }
      }
    }
    return isUsedOnPricingFilter;
  }
}
