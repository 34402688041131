export interface SyncReader {
  id: number;
  name: string;
  description: string;
  query: string;
  pageSize: number;  
  httpMethodID: number;
  httpMethod: {
    name: string,
    id: number
  };
}

export const emptySyncReader: SyncReader = {
  id: null,
  name: '',
  description: '',
  query: '',
  pageSize: null,
  httpMethodID: null,
  httpMethod: {
    name: '',
    id: null
  },
};
