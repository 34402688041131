<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/SyncMap.png" alt="Sync Map" style="padding-right: 5px;">
      Create / Update Sync Map
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="syncMapForm">
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
                  <kendo-textbox class="form-control"
                                 formControlName="name">
                  </kendo-textbox>
                  <kendo-formerror>Name is required</kendo-formerror>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="description" text="Description"></kendo-label>
                  <kendo-textbox class="form-control"
                                 formControlName="description">
                  </kendo-textbox>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <div class="wrap" style="padding-right: 10px; padding-left: 18px;">
                  <input class="form-check-input" type="checkbox" formControlName="isActive" id="isActive" />
                  <kendo-label class="k-checkbox-label" for="isActive" text="Is Active"></kendo-label>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="triggeringEntityID" class="font-weight-bold" text="* Triggering Entity Type"></kendo-label>
                  <ng-select id="triggeringEntity"
                             [multiple]="false"
                             [selectOnTab]="true"
                             formControlName="triggeringEntityID"
                             [notFoundText]="'Please start typing to search'"
                             [ngClass]="{
                        'is-invalid': submitted && f.triggeringEntityID.errors,
                        'w-100': true
                      }"
                             placeholder="Select an Entity Type"
                             [searchable]="true" appendTo="body"
                             (change)="onSelectBoxChanged($event, 'triggeringEntity', triggeringEntityTypes)">
                      <ng-option *ngFor="let item of triggeringEntityTypes" [value]="item.id">
                          {{ item.name }}
                      </ng-option>
                  </ng-select>
                  <kendo-formerror>Entity Type is required</kendo-formerror>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="rank" text="Rank"></kendo-label>
                  <kendo-numerictextbox class="form-control" formControlName="rank" [min]="1" [autoCorrect]="true" [min]="1"
                                        [decimals]="0" [format]="'n0'" [ngClass]="{ 'is-invalid': submitted && f.rank.errors }"
                                        [max]="999" placeholder="Enter a Rank">
                  </kendo-numerictextbox>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="queueTypeID" class="font-weight-bold" text="* Queue Type"></kendo-label>
                  <ng-select id="queueType"
                             [multiple]="false"
                             [selectOnTab]="true"
                             formControlName="queueTypeID"
                             [notFoundText]="'Please start typing to search'"
                             [ngClass]="{
                        'is-invalid': submitted && f.queueTypeID.errors,
                        'w-100': true
                      }"
                             placeholder="Select a Queue Type"
                             [searchable]="true" appendTo="body"
                             (change)="onSelectBoxChanged($event, 'queueType', queueTypes)">
                      <ng-option *ngFor="let item of queueTypes" [value]="item.id">
                          {{ item.name }}
                      </ng-option>
                  </ng-select>
                  <kendo-formerror>Queue Type is required</kendo-formerror>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="syncReader" class="font-weight-bold" text="* Sync Reader"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                      <ng-select id="syncReader" class="noarrow" [multiple]="false" [selectOnTab]="true"
                                 formControlName="syncReaderID" [notFoundText]="'Please start typing to search'"
                                 [ngClass]="{'borderless': true, 'is-invalid': submitted && f.syncReaderID.errors,'w-100': true }"
                                 placeholder="Select a Sync Reader" [searchable]="true" (search)="search($event, filters.SyncReader)"
                                 (change)="onSelectBoxChanged($event,'syncReaderID',syncReaders)">
                          <ng-option *ngFor="let item of syncReaders" [value]="item.id">
                              {{ item.name }}
                          </ng-option>
                      </ng-select>
                      <kendo-textbox-separator></kendo-textbox-separator>
                      <button kendoButton look="clear" (click)="onLookup_SyncReader()" icon="search"></button>
                  </span>
                  <kendo-formerror>Sync Reader is required</kendo-formerror>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="syncWriter" class="font-weight-bold" text="* Sync Writer"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                      <ng-select id="syncWriter" class="noarrow" [multiple]="false" [selectOnTab]="true"
                                 formControlName="syncWriterID" [notFoundText]="'Please start typing to search'"
                                 [ngClass]="{'borderless': true, 'is-invalid': submitted && f.syncWriterID.errors,'w-100': true }"
                                 placeholder="Select a Sync Writer" [searchable]="true" (search)="search($event, filters.SyncWriter)"
                                 (change)="onSelectBoxChanged($event,'syncWriterID',syncWriters)">
                          <ng-option *ngFor="let item of syncWriters" [value]="item.id">
                              {{ item.name }}
                          </ng-option>
                      </ng-select>
                      <kendo-textbox-separator></kendo-textbox-separator>
                      <button kendoButton look="clear" (click)="onLookup_SyncWriter()" icon="search"></button>
                  </span>
                  <kendo-formerror>Sync Writer is required</kendo-formerror>
              </kendo-formfield>
          </div>
      </div>
      <div class="row">
          <div class="col form-group">
              <kendo-formfield>
                  <kendo-label for="filter" text="Filter"></kendo-label>
                  <kendo-textbox class="form-control"
                                 formControlName="filter">
                  </kendo-textbox>
              </kendo-formfield>
          </div>
      </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
