import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { MySnackBarService } from '../../shared/snackbar/my-snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  model = 'RPMUtilities';
  constructor(private httpClient: HttpClient,
    private snack: MySnackBarService) { }

  async callStoredProc(ProcName: string, ProcParams: string = '[]'): Promise<HttpResponse<string>> {
    const url = `${this.model}?ProcName=${ProcName}`;
    return this.httpClient
      .post(url, ProcParams, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text', observe: 'response' })
      .toPromise() as Promise<HttpResponse<string>>;
  }

  async callStoredProcWithPaging(ProcName: string, originalProcParams: any = [], RecordCount: number, PageSize: number): Promise<string[]> {
    const url = `${this.model}?ProcName=${ProcName}`;
    const totalPages = Math.ceil(RecordCount / PageSize);
    let responses: string[] = [];

    for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
      const pageProcParams = [...originalProcParams, { Name: "@PageNumber", Value: currentPage }, { Name: "@PageSize", Value: PageSize }];
      const message = `Processing ${((currentPage / totalPages) * 100).toFixed(2)}% complete.`;
      this.snack.openSnackBar(message, '', false, 'Success', 'alert-success');

      try {
        const response = await this.httpClient.post<string>(url, JSON.stringify(pageProcParams), {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          responseType: 'json',
          observe: 'body'
        }).toPromise();
        responses.push(response);
      } catch (error) {
        this.snack.openSnackBar('Error processing request', '', false, 'Error', 'alert-error');
        console.error("Error processing request: ", error);
        // Consider how to handle partial failures - e.g., continue, accumulate errors, etc.
      }
    }
    return responses;
  }

}


