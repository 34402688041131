import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DocumentAttribute } from './document-attributes.model';
import { clearParamsFromNull } from '../params-utils/index';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/utils/StorageHelper';

@Injectable({
  providedIn: 'root',
})
export class DocumentAttributesService {
  model = 'RPMDocumentAttributes';
  constructor(private httpClient: HttpClient) {}
  getList(params = {}): Promise<HttpResponse<DocumentAttribute[]>> {
    params = clearParamsFromNull(params);
    // @ts-ignore
    // observe: 'response'
    return this.httpClient
      .get(this.model, { params, observe: 'response' })
      .toPromise() as Promise<HttpResponse<DocumentAttribute[]>>;
  }
  getExportList(params = {}): Promise<GridDataResult> {
    params = clearParamsFromNull(params);
    // @ts-ignore
    // observe: 'response'
    return this.httpClient
      .get(this.model, { params, observe: 'response' })
      .pipe(
        map(response => (<GridDataResult>{
              data: response.body,
              total: 200000,
            })))
      .toPromise() as Promise<GridDataResult>;
  }
  getById(id: number): Promise<DocumentAttribute> {
    return this.httpClient.get(`${this.model}/${id}`).toPromise() as Promise<
      DocumentAttribute
    >;
  }
  removeById(id: number) {
    return this.httpClient.delete(`${this.model}/${id}`).toPromise();
  }
  removeByIds(ids: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
      }),
      body: ids,
    };
    return this.httpClient.delete(`${this.model}/`, options).toPromise();
  }
  create(data): Promise<HttpResponse<DocumentAttribute>> {
    data.CreatedOn = new Date();
    data.CreatedBy = StorageService.UserID();
    return this.httpClient
    .post(this.model, data, {observe: 'response'})
    .toPromise() as Promise<HttpResponse<DocumentAttribute>>;
  }
  update(id, data): Promise<HttpResponse<DocumentAttribute>>  {
    data.ModifiedOn = new Date();
    data.ModifiedBy = StorageService.UserID();
    return this.httpClient
    .put(`${this.model}/${id}`, data, {observe: 'response'})
    .toPromise() as Promise<HttpResponse<DocumentAttribute>>;
  }
}
