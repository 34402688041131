<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  Catalog Lines
</div>
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [sort]="query.SortTerm"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" id="btnAdd" class="showTooltip" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="!this.catalogSheetID"
              imageUrl="../../../../assets/images/Add.png" (click)="onCreate()" title="Save the record above to be able to start adding these records here.">
        Add
        New
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn"
              imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">
        Edit
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
              imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()"
              title="Delete Selected">
        Delete
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)"
              title="Export to Excel">
        Export
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()"
              title="Import from Excel">
        Import
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Get_Price.png" (click)="onClickCreateTests()" title="Create Pricing Test Scenario for Selected Record">CREATE TEST</button>

    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>

  <kendo-grid-column field="priceSchedule.name" title="Price Schedule" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.priceSchedule">
        <a [routerLink]="['/price/price-schedule/', dataItem.priceSchedule.id]"> {{ dataItem.priceSchedule.name }} </a>
        <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)"> {{ dataItem.priceSchedule.name }} </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="customer.name" title="Customer Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.customer">
        <a [routerLink]="['/price/customer/', dataItem.customer?.id]"> {{ dataItem.customer?.name }} </a>
        <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)"> {{ dataItem.customer?.name }} </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="customer.number" title="Customer Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.customer"> {{ dataItem.customer?.number }} </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.name" title="Item Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem?.item">
        <a [routerLink]="['/price/item/', dataItem.item?.id]"> {{ dataItem.item?.name }} </a>
        <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)"> {{ dataItem.item?.name }} </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="item.number" title="Item Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.item"> {{ dataItem.item?.number }} </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="uofM.name" title="Unit of Measure" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.uofM"> {{ dataItem.uofM?.name }} </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="externalItemNumber" title="External Item Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="quantity" title="Quantity" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="location.name" title="Location" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.location">
        <a [routerLink]="['/price/location/', dataItem.location?.id]"> {{ dataItem.location?.name }} </a>
        <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)"> {{ dataItem.location?.name }} </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="currency.name" title="Currency" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.currency"> {{ dataItem.currency?.name }} </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="effectivedate" title="Effective Date" width="175" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.effectiveDate | date: 'MM/dd/yyyy' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="benchmarkedUnitPrice" title="Benchmarked Price" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="lastBenchmarkedDateTime" title="Last Benchmarked" width="175" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.lastBenchmarkedDateTime | date: 'MM/dd/yyyy' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="comment" title="Comment" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="calculatedUnitPrice" title="Calculated Price" [headerStyle]="{'background-color': '#999','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="calculatedPriceSchedule.name" title="Calculated Price Schedule" [headerStyle]="{'background-color': '#999','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.calculatedPriceSchedule"> {{ dataItem.calculatedPriceSchedule?.name }} </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="lastCalculatedDateTime" title="Last Calculated" width="175" [headerStyle]="{'background-color': '#999','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.lastCalculatedDateTime | date: 'MM/dd/yyyy' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="processStatus" title="Process Status" [headerStyle]="{'background-color': '#999','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="errorMessage" title="Error Message" [headerStyle]="{'background-color': '#999','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="false" field="importWarning">
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="CatalogLines.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="catalogSheet.catalog.name" title="CatalogName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="catalogSheet.name" title="CatalogSheetName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceSchedule.name" title="PriceScheduleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.name" title="CustomerName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.number" title="CustomerNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="item.name" title="ItemName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="item.number" title="ItemNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="uofM.name" title="UnitOfMeasureName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="externalItemNumber" title="ExternalItemNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="quantity" title="Quantity" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="location.name" title="LocationName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="currency.name" title="CurrencyName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="currency.alphaCode" title="CurrencyAlphaCode" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="effectiveDate" title="EffectiveDate" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="benchmarkedUnitPrice" title="BenchmarkedUnitPrice" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastBenchmarkedDateTime" title="LastBenchmarkedDateTime" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="comment" title="Comment" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="importWarning" title="Reminder: Calculated fields cannot be imported" *ngIf="exportAll"></kendo-excelexport-column>
    <!--Note: Next 6 fields should not export for re-import but should export for display if shown in the grid.-->
    <kendo-excelexport-column field="calculatedUnitPrice" title="CalculatedUnitPrice" *ngIf="!exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="calculatedPriceSchedule.name" title="CalculatedPriceScheduleName" *ngIf="!exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastCalculatedDateTime" title="LastCalculated" *ngIf="!exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="processStatus" title="ProcessStatus" *ngIf="!exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="errorMessage" title="ErrorMessage" *ngIf="!exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
