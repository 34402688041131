import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceCalculationsService } from '../../../services/price-calculations/price-calculations.service';
import { PriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent, ExcelModule, GridComponent, ExcelComponent, FooterComponent, RowArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PriceFilter } from 'src/app/services/price-filter/price-filter.model';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-price-calculation-list',
  templateUrl: './price-calculation-list.component.html',
  styleUrls: ['./price-calculation-list.component.scss'],
})
export class PriceCalculationListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private priceCalculationsService: PriceCalculationsService,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input()
  loading: boolean = true;
  @Input()
  priceCalculations: PriceCalculation[] = [];
  @Input()
  query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input()
  pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;

  pagination_priceCalculations: any = { priceCalculations: mockedData };
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PriceFilter[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public priceCalculationPriceModeOptions: Lookup[];
  public priceCalculationEntity: Lookup[] = [];
  public filterable: boolean;
  //public lastUpdateTime = 0;
  //public updateInterval = 500; // Update every 500ms at most
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 175,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Price Mode',
      field: 'priceModeOption.name',
      width: 100,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Modify Unit Price',
      field: 'modifyUnitPrice',
      width: 150,
      orderIndex: 2,
      hidden: true,
    },
    {
      title: 'From The Base Starting Price',
      field: 'fromTheBaseStartingPrice',
      width: 150,
      orderIndex: 3,
      hidden: true,
    },
    {
      title: 'Amount',
      field: 'priceAmount',
      width: 75,
      orderIndex: 4,
      hidden: false,
    },
    {
      title: 'Percent',
      field: 'pricePercent',
      width: 75,
      orderIndex: 5,
      hidden: false,
    },
    {
      title: 'Pricing Filter',
      field: 'pricingFilter.name',
      width: 150,
      orderIndex: 6,
      hidden: false,
    },
    {
      title: 'Pricing Condition',
      field: 'pricingCondition.name',
      width: 150,
      orderIndex: 7,
      hidden: true,
    },
    {
      title: 'Rounding Rule',
      field: 'roundingRule.name',
      width: 150,
      orderIndex: 8,
      hidden: true,
    },
    //{
    //  title: 'Use Value from Attribute',
    //  field: 'useValueFromAttribute',
    //  width: 150,
    //  orderIndex: 9,
    //  hidden: true,
    //},
    //{
    //  title: 'Entity',
    //  field: 'entity.name',
    //  width: 150,
    //  orderIndex: 10,
    //  hidden: true,
    //},
    //{
    //  title: 'Customer Attribute',
    //  field: 'customerAttribute.name',
    //  width: 150,
    //  orderIndex: 11,
    //  hidden: true,
    //},
    //{
    //  title: 'Item Attribute',
    //  field: 'itemAttribute.name',
    //  width: 150,
    //  orderIndex: 12,
    //  hidden: true,
    //},
    //{
    //  title: 'Document Attribute',
    //  field: 'documentAttribute.name',
    //  width: 150,
    //  orderIndex: 13,
    //  hidden: true,
    //},
    //{
    //  title: 'Price Book',
    //  field: 'priceBook.name',
    //  width: 150,
    //  orderIndex: 14,
    //  hidden: true,
    //},
    //{
    //  title: 'Attribute Map',
    //  field: 'attributeMap.name',
    //  width: 150,
    //  orderIndex: 15,
    //  hidden: true,
    //},
    //{
    //  title: 'Convert To UofM',
    //  field: 'convertToUofM',
    //  width: 150,
    //  orderIndex: 16,
    //  hidden: true,
    //},
    //{
    //  title: 'Per UofM Name',
    //  field: 'perUofMName',
    //  width: 150,
    //  orderIndex: 17,
    //  hidden: true,
    //},
    //{
    //  title: 'Track Adjustment Extended',
    //  field: 'trackAdjustmentExtended',
    //  width: 150,
    //  orderIndex: 18,
    //  hidden: true,
    //},
    //{
    //  title: 'Track Adjustment',
    //  field: 'trackAdjustment',
    //  width: 150,
    //  orderIndex: 19,
    //  hidden: true,
    //},
    //{
    //  title: 'Debit GL Account',
    //  field: 'debitGLAccount.name',
    //  width: 150,
    //  orderIndex: 20,
    //  hidden: true,
    //},
    //{
    //  title: 'Credit GL Account',
    //  field: 'creditGLAccount.name',
    //  width: 150,
    //  orderIndex: 21,
    //  hidden: true,
    //},
    //{
    //  title: 'User Can Edit',
    //  field: 'userCanEdit',
    //  width: 150,
    //  orderIndex: 22,
    //  hidden: true,
    //},
    //{
    //  title: 'Affects Margin Calc',
    //  field: 'affectsMarginCalc',
    //  width: 150,
    //  orderIndex: 23,
    //  hidden: true,
    //},
    //{
    //  title: 'Abort If Value Not Found',
    //  field: 'abortIfValueNotFound',
    //  width: 150,
    //  orderIndex: 24,
    //  hidden: true,
    //},
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.priceCalculationsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMPriceCalculation", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully! Please refresh the grid to see results.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async getPriceCalculationPriceModeOptions() {
    const Filter_OptionSet = 'PriceMode';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.priceCalculationPriceModeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "PriceMode";
    defaultLookup.name = "(All)";
    this.priceCalculationPriceModeOptions.reverse();
    this.priceCalculationPriceModeOptions.push(defaultLookup);
    this.priceCalculationPriceModeOptions.reverse();
    return this.priceCalculationPriceModeOptions;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PriceCalculationList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PriceCalculationList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PriceCalculationList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PriceCalculationList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
    this.getPriceCalculationPriceModeOptions();
    this.getPriceCalculationEntity();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  async getPriceCalculationEntity() {
    this.priceCalculationEntity.length = 0;
    this.priceCalculationEntity.push({ id: 999, name: "(All)", optionSet: "EntityType", sortOrder: 1 });
    this.priceCalculationEntity.push({ id: 75, name: "Customer Attribute", optionSet: "EntityType", sortOrder: 2 });
    this.priceCalculationEntity.push({ id: 70, name: "Document Attribute", optionSet: "EntityType", sortOrder: 3 });
    this.priceCalculationEntity.push({ id: 76, name: "Item Attribute", optionSet: "EntityType", sortOrder: 4 });
    this.priceCalculationEntity.push({ id: 77, name: "Price Book", optionSet: "EntityType", sortOrder: 5 });
    this.priceCalculationEntity.push({ id: 85, name: "Attribute Map", optionSet: "EntityType", sortOrder: 6 });
    return this.priceCalculationEntity;
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMPriceCalculation');
    this.fileUploader.nativeElement.value = null;
    this.loadListData(); 
  }

  //async onFileChangeOLD(evt: any) {
  //  const target: DataTransfer = <DataTransfer>evt.target;

  //  if (target.files.length !== 1) throw new Error('Cannot use multiple files');

  //  const reader: FileReader = new FileReader();

  //  reader.onload = async (e: any) => {
  //    const bstr: string = e.target.result;
  //    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
  //    const wsname: string = wb.SheetNames[0];
  //    const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  //    //ws.forEach(function(y) {
  //    //var worksheet = wb.Sheets[y];
  //    var headers = {};
  //    var data = [];
  //    for (const z in ws) {
  //      if (z[0] === '!') continue;

  //      const match = z.match(/([A-Z]+)(\d+)/);
  //      if (!match) continue;
  //      const [, col, rowString] = match;
  //      const row = parseInt(rowString);
  //      const value = ws[z].v;

  //      //store header names
  //      if (row == 1) {
  //        headers[col] = String(value);
  //        continue;
  //      }

  //      if (!data[row]) data[row] = {};
  //      data[row][headers[col]] = String(value).trim();
  //    }
  //    //drop those first two rows which are empty
  //    data.shift();
  //    data.shift();

  //    const chunkSize = 250;
  //    const chunks = [];
  //    for (let i = 0; i < data.length; i += chunkSize) {
  //      const chunk = data.slice(i, i + chunkSize);
  //      chunks.push(chunk);
  //    }
  //    const totalChunks = chunks.length;

  //    for (let index = 0; index < chunks.length; index++) {
  //      const chunk = chunks[index];
  //      var myJSONString = JSON.stringify(chunk);
  //      var myEscapedJSONString = myJSONString
  //        .replace(/[\\]/g, '\\\\')
  //        .replace(/[\']/g, "\\'")
  //        .replace(/[\"]/g, '\\"')
  //        .replace(/[\/]/g, '\\/')
  //        .replace(/[\b]/g, '\\b')
  //        .replace(/[\f]/g, '\\f')
  //        .replace(/[\n]/g, '\\n')
  //        .replace(/[\r]/g, '\\r')
  //        .replace(/[\t]/g, '\\t');

  //      const inputXML = "'" + myEscapedJSONString + "'";
  //      const percentComplete = Math.round(((index + 1) / totalChunks) * 100);
  //      await this.performImportOLD(inputXML, percentComplete);

  //    };
  //  };

  //  reader.readAsBinaryString(target.files[0]);

  //  this.fileUploader.nativeElement.value = null;
  //}


  //async performImportOLD(inputXML, percentComplete) {
  //  try {
  //    const response = await this.importsService.performImport(
  //      inputXML,
  //      'RPMPriceCalculation',
  //    );
  //    const status = response.status;
  //    if (status === 200) {
  //      const currentTime = new Date().getTime();
  //      if (currentTime - this.lastUpdateTime > this.updateInterval) {
  //        this.snack.openSnackBar(
  //          'Import Progress: ' + percentComplete + '%',
  //          '',
  //          false,
  //          'Success',
  //          'alert-success',
  //        );
  //        this.lastUpdateTime = currentTime;
  //      }
  //      if (percentComplete === 100) {
  //        this.snack.openSnackBar(
  //          'Import Complete ' + response.body,
  //          '',
  //          false,
  //          'Success',
  //          'alert-success',
  //        );
  //        this.loadListData();
  //      }
  //    }
  //  } catch (e) {
  //    this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
  //  }
  //}

  //async performImport(inputXML, percentComplete) {
  //  try {
  //    const response: any = await this.importsService.performImport(
  //      inputXML,
  //      'RPMPriceCalculation',
  //    );
  //    const status: any = response.status;
  //    if (status === 200) {
  //      this.snack.openSnackBar(
  //        'Import Complete: ' + percentComplete + '%' + response.body,
  //        '',
  //        false,
  //        'Success',
  //        'alert-success',
  //      );
  //      //this.loadListData();
  //    }
  //  } catch (e) {
  //    this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
  //  }
  //}

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/price-calculation/' + this.clickedRowItem.id]);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'priceAmount') {
          const Filter_PriceAmount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PriceAmount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PriceAmount,
            Operand_PriceAmount,
          };
          this.query = params;
        }
        if (myFilter.field == 'pricePercent') {
          const Filter_PricePercent =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricePercent = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricePercent,
            Operand_PricePercent,
          };
          this.query = params;
        }
        if (myFilter.field == 'convertToUofM') {
          const Filter_ConvertToUofM = myFilter.value;
          const Operand_ConvertToUofM = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ConvertToUofM,
            Operand_ConvertToUofM,
          };
          this.query = params;
        }
        if (myFilter.field == 'perUofMName') {
          const Filter_PerUofMName = myFilter.value == null ? '' : myFilter.value;
          const Operand_PerUofMName = myFilter.operator;
          const params = { ...this.query, Filter_PerUofMName, Operand_PerUofMName };
          this.query = params;
        }
        if (myFilter.field == 'modifyUnitPrice') {
          const Filter_ModifyUnitPrice = myFilter.value;
          const Operand_ModifyUnitPrice = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ModifyUnitPrice,
            Operand_ModifyUnitPrice,
          };
          this.query = params;
        }
        if (myFilter.field == 'trackAdjustment') {
          const Filter_TrackAdjustment = myFilter.value;
          const Operand_TrackAdjustment = myFilter.operator;
          const params = {
            ...this.query,
            Filter_TrackAdjustment,
            Operand_TrackAdjustment,
          };
          this.query = params;
        }
        if (myFilter.field == 'trackAdjustmentExtended') {
          const Filter_TrackAdjustmentExtended = myFilter.value;
          const Operand_TrackAdjustmentExtended = myFilter.operator;
          const params = {
            ...this.query,
            Filter_TrackAdjustmentExtended,
            Operand_TrackAdjustmentExtended,
          };
          this.query = params;
        }
        if (myFilter.field == 'userCanEdit') {
          const Filter_UserCanEdit = myFilter.value;
          const Operand_UserCanEdit = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UserCanEdit,
            Operand_UserCanEdit,
          };
          this.query = params;
        }
        if (myFilter.field == 'affectsMarginCalc') {
          const Filter_AffectsMarginCalc = myFilter.value;
          const Operand_AffectsMarginCalc = myFilter.operator;
          const params = {
            ...this.query,
            Filter_AffectsMarginCalc,
            Operand_AffectsMarginCalc,
          };
          this.query = params;
        }
        if (myFilter.field == 'roundingRule.name') {
          const Filter_RoundingRule =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_RoundingRule = myFilter.operator;
          const params = {
            ...this.query,
            Filter_RoundingRule,
            Operand_RoundingRule,
          };
          this.query = params;
        }
        if (myFilter.field == 'debitGLAccount.name') {
          const Filter_DebitGLAccount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_DebitGLAccount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_DebitGLAccount,
            Operand_DebitGLAccount,
          };
          this.query = params;
        }
        if (myFilter.field == 'creditGLAccount.name') {
          const Filter_CreditGLAccount =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_CreditGLAccount = myFilter.operator;
          const params = {
            ...this.query,
            Filter_CreditGLAccount,
            Operand_CreditGLAccount,
          };
          this.query = params;
        }
        if (myFilter.field == 'priceModeOption.name') {
          if (myFilter.value != '(All)') {
            const Filter_PriceModeOption = myFilter.value;
            const Operand_PriceModeOption = myFilter.operator;
            const params = {
              ...this.query,
              Filter_PriceModeOption,
              Operand_PriceModeOption,
            };
            this.query = params;
          }
        }
        if (myFilter.field == 'pricingFilter.name') {
          const Filter_PricingFilter =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricingFilter = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricingFilter,
            Operand_PricingFilter,
          };
          this.query = params;
        }
        if (myFilter.field == 'pricingCondition.name') {
          const Filter_PricingCondition =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_PricingCondition = myFilter.operator;
          const params = {
            ...this.query,
            Filter_PricingCondition,
            Operand_PricingCondition,
          };
          this.query = params;
        }
        if (myFilter.field == 'useValueFromAttribute') {
          const Filter_UseValueFromAttribute = myFilter.value;
          const Operand_UseValueFromAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UseValueFromAttribute,
            Operand_UseValueFromAttribute,
          };
          this.query = params;
        }
        if (myFilter.field == 'entity.name') {
          if (myFilter.value != '(All)') {
            const Filter_Entity = myFilter.value;
            const Operand_Entity = myFilter.operator;
            const params = {
              ...this.query,
              Filter_Entity,
              Operand_Entity,
            };
            this.query = params;
          }
        }
        if (myFilter.field == 'customerAttribute.name') {
          const Filter_CustomerAttribute =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_CustomerAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_CustomerAttribute,
            Operand_CustomerAttribute,
          };
          this.query = params;
        }
        if (myFilter.field == 'documentAttribute.name') {
          const Filter_DocumentAttribute =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_DocumentAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_DocumentAttribute,
            Operand_DocumentAttribute,
          };
          this.query = params;
        }
        if (myFilter.field == 'itemAttribute.name') {
          const Filter_ItemAttribute =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ItemAttribute = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ItemAttribute,
            Operand_ItemAttribute,
          };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.priceCalculationsService.getExportList(this.query);
  };
  edit(id: number) {
    this.router.navigate([`/price/price-calculation/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.priceCalculationsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceCalculationsService.getList(this.query);
      this.priceCalculations = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.priceCalculations,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
