export interface SyncMap {
  id: number;
  name: string;
  description: string;
  triggeringEntityID: number;
  triggeringEntity: {
    name: string,
    id: number
  };
  rank: number;
  queueTypeID: number;
  queueType: {
    name: string,
    id: number
  };
  syncReaderID: number;
  syncReader: {
    name: string,
    id: number
  };
  syncWriterID: number;
  syncWriter: {
    name: string,
    id: number
  };
  isActive: boolean;
  filter: string;
}

export const emptySyncMap: SyncMap = {
  id: null,
  name: '',
  description: '',  
  triggeringEntityID: null,
  triggeringEntity: {
    name: '',
    id: null
  },
  rank: null,
  queueTypeID: null,
  queueType: {
    name: '',
    id: null
  },
  syncReaderID: null,
  syncReader: {
    name: '',
    id: null
  },
  syncWriterID: null,
  syncWriter: {
    name: '',
    id: null
  },
  isActive: false,
  filter: ''
};
