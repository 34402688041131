<kendo-dialog [minWidth]="550" (close)="onClick_Close('Cancel')" [width]="1200">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; max-height: 500px; overflow-y: auto; line-height: 1.3em; ">
      <img src="../../../../assets/images/Reports.png" alt="Report" style="padding-right: 5px;">
      Create / Update Report
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="reportForm">
    <div class="row">
      <div class="col form-group col-9">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Name" maxlength="100" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-3">
        <kendo-formfield>
          <kendo-label for="ranOn" text="Ran On"></kendo-label>
          <kendo-textbox class="form-control" formControlName="ranOn" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>


    <kendo-tabstrip (tabSelect)="onTabSelect($event)" [tabPosition]="'top'">
      <kendo-tabstrip-tab [title]="'Results'" [selected]="true">
        <ng-template kendoTabContent>
          <div class="card p-3" style="background-color:rgb(234, 234, 234)">
            <kendo-grid style="height: 400px; " #grid="kendoGrid"
                        [data]="gridView"
                        [pageable]="true"
                        (pageChange)="pageChange($event)"
                        [pageSize]="5">
              <ng-template kendoGridToolbarTemplate>
                <kendo-buttongroup>
                  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onClickRefresh()" title="Refresh">Refresh</button>
                  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Price_Calculation.png" (click)="onClickCalculate()" title="Regenerate Report">Regenerate</button>
                  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClickExport(grid, exportToExcel)" title="Export to Excel">Export</button>
                </kendo-buttongroup>
              </ng-template>
              <kendo-grid-excel #exportToExcel fileName="Report.xlsx">
              </kendo-grid-excel>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="'Definition'">
        <ng-template kendoTabContent>
          <div class="card p-3" style="background-color:rgb(234, 234, 234)">
            <div class="row">
              <div class="col-12 form-group">
                <kendo-formfield style="width: 100%;">
                  <kendo-label for="sqlScriptText" text="SQL Script"> </kendo-label>
                  <kendo-textarea class="form-control" formControlName="sqlScriptText" rows="15" style="overflow-y:auto; width:100%;"
                                  [readonly]="!isRocktonAdmin" title="Scripts can only be edited by Rockton Support">
                  </kendo-textarea>
                </kendo-formfield>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>

  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_SaveClose()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save & Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
